import React from "react";
import config from "config.json";
import type { TagAPI } from "@data/types";

interface TagCarouselProps {
  tags: TagAPI[];
}

export const TagCarouselClient: React.FC<TagCarouselProps> = ({ tags }) => {
  return (
    <div className="carousel w-full gap-4">
      {tags.map((tag) => (
        <div key={tag.id} className="carousel-item flex flex-col gap-4 group">
          <a href={`/tag/${tag.slug}`} aria-label={tag.name}>
            <div className="w-[135px] h-[135px] rounded-full overflow-hidden items-center  justify-center">
              {tag.thumbnail ? (
                <img
                  src={`${tag.thumbnail.sizes?.thumbnail?.url || tag.thumbnail.sizes?.tablet?.url}`}
                  alt={tag.name}
                  className="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                />
              ) : (
                <div className="w-full h-full bg-gray-200 flex items-center justify-center transition-transform duration-300 ease-in-out group-hover:scale-110">
                  <span className="text-gray-500 text-xl">{tag.name.charAt(0)}</span>
                </div>
              )}
            </div>
          </a>
          <div className="text-center font-bold">{tag.name}</div>
        </div>
      ))}
    </div>
  );
};
